<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <!-- <el-breadcrumb-item>学期设置</el-breadcrumb-item> -->
      </el-breadcrumb>
    </div>
    <div class="frombox">
      <div class="titlename">
        <div
          style="
            display: flex;
            justify-content: space-between;
            padding-right: 60px;
          ">
          <div>学期设置</div>

          <div>
            <el-button plain @click="addSemester" size="mini">新增</el-button>
          </div>
        </div>
        <div class="tabs">
          <el-table :data="postLists" class="table" v-loading="false">
            <el-table-column
              align="center"
              label="ID"
              width="50"
              type="index"
              prop="id">
              <template slot-scope="scope">
                <span>{{ scope.row.id || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="学年" align="center" prop="semester_year">
              <template slot-scope="scope">
                <div v-if="scope.row.semester_year != null">
                  <span>{{ scope.row.semester_year || "-" }}</span>
                </div>
                <div v-else>
                  <span>-</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="学期"
              align="end"
              prop="semester_season"
              width="160px">
              <template slot-scope="scope">
                <span v-if="scope.row.semester_season == 1">第一学期</span>
                <span v-if="scope.row.semester_season == 2">第二学期</span>
                &nbsp;
                <span v-if="scope.row.is_enable == 1">当前学期</span>
              </template>
            </el-table-column>
            <el-table-column
              label="本学期开始时间"
              align="center"
              prop="semester_start_time"
              min-width="140">
              <template slot-scope="scope">
                <span>{{ scope.row.semester_start_time || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="本学期结束时间"
              align="center"
              prop="semester_end_time"
              min-width="140">
              <template slot-scope="scope">
                <span>{{ scope.row.semester_end_time || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="本学期周数"
              align="center"
              prop="semester_week_num"
              min-width="100">
              <template slot-scope="scope">
                <span class="wenbenxianshi">{{
                  scope.row.semester_week_num || "-"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              fixed="right"
              class-name="small-padding fixed-width">
              <template slot-scope="scope">
                <el-button type="text" @click="bianji(scope.row)"
                  >修改</el-button
                >
                <el-button
                  type="text"
                  style="color: red"
                  @click="deleteById(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="frombox">
      <el-form
        :model="fromData"
        ref="fromData"
        label-width="180px"
        :rules="rules">
        <!-- <el-form-item label="夏季时切换日期:" prop="summer_time">
          <div class="shurubox1">
            <el-date-picker
              v-model="fromData.summer_time"
              type="date"
              placeholder="选择夏季时间开始日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd">
            </el-date-picker>
            指达到改日期切换到夏季时（当日0点）
          </div>
        </el-form-item>
        <el-form-item label="冬季时切换日期:" prop="winter_time">
          <div class="shurubox1">
            <el-date-picker
              v-model="fromData.winter_time"
              type="date"
              placeholder="选择夏季时间开始日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd">
            </el-date-picker>
            指达到改日期切换到冬季时（当日0点）
          </div>
        </el-form-item> -->
        <el-form-item label="课程时间：" prop="schedule">
          <div style="display: flex">
            <div
              class="jijiebox"
              :class="{ active: act == index }"
              v-for="(item, index) in items"
              :key="index"
              @click="changeact(index)">
              {{ item.name }}
            </div>
          </div>
        </el-form-item>
        <div class="zuoxibox" v-if="act == 0">
          <!-- 夏季 -->
          <div
            class="kejieshu flexSt"
            v-for="(item, index) in fromData.schedule.summer"
            :key="index">
            <!-- 第{{ index + 1 }}节 -->
            {{
              item.course_type == 2 ? "大课间" : "第" + item.class_num + "节"
            }}
            <el-time-select
              placeholder="起始时间"
              v-model="fromData.schedule.summer[index].start_time"
              @change="timevaluechange1"
              :picker-options="{
                start: '05:30',
                step: '00:05',
                end: '23:30',
              }"
              value-format="HH:mm:ss">
            </el-time-select>
            <el-time-select
              placeholder="结束时间"
              v-model="fromData.schedule.summer[index].end_time"
              @change="timevaluechange1"
              :picker-options="{
                start: '05:30',
                step: '00:05',
                end: '23:30',
                minTime: fromData.schedule.summer[index].start_time,
              }"
              value-format="HH:mm:ss">
            </el-time-select>
            <div class="flexSt">
              <div class="switch_title">设置成大课间</div>
              <el-switch
                :active-value="2"
                :inactive-value="1"
                @change="handleChangesummer(fromData.schedule.summer[index])"
                class="switch"
                v-model="fromData.schedule.summer[index].course_type">
              </el-switch>
              <el-button
                icon="el-icon-plus"
                size="mini"
                circle
                @click="addkeshi(index)"></el-button>
              <el-button
                v-if="index > 0"
                icon="el-icon-minus"
                size="mini"
                circle
                @click="deleyes(index)"></el-button>
            </div>

            <br />
          </div>
        </div>
        <div class="zuoxibox" v-if="act == 1">
          <!-- 冬季 -->
          <div
            class="kejieshu flexSt"
            v-for="(item, index) in fromData.schedule.winter"
            :key="index">
            {{
              item.course_type == 2 ? "大课间" : "第" + item.class_num + "节"
            }}
            <el-time-select
              placeholder="起始时间"
              v-model="fromData.schedule.winter[index].start_time"
              @change="timevaluechange1"
              :picker-options="{
                start: '05:30',
                step: '00:05',
                end: '23:30',
              }"
              value-format="HH:mm:ss">
            </el-time-select>
            <el-time-select
              placeholder="结束时间"
              v-model="fromData.schedule.winter[index].end_time"
              @change="timevaluechange1"
              :picker-options="{
                start: '05:30',
                step: '00:05',
                end: '23:30',
                minTime: fromData.schedule.winter[index].startTime,
              }"
              value-format="HH:mm:ss">
            </el-time-select>

            <div class="flexSt">
              <div class="switch_title">设置成大课间</div>
              <el-switch
                :active-value="2"
                :inactive-value="1"
                class="switch"
                @change="handleChangewinter(fromData.schedule.winter[index])"
                v-model="fromData.schedule.winter[index].course_type">
              </el-switch>
              <el-button
                icon="el-icon-plus"
                size="mini"
                circle
                @click="addkeshi(index)"></el-button>
              <el-button
                v-if="index > 0"
                icon="el-icon-minus"
                size="mini"
                circle
                @click="deleyes(index)"></el-button>
            </div>

            <br />
          </div>
        </div>
      </el-form>
      <div class="anniu">
        <el-button
          class="resBtn"
          size="mini"
          type="primary"
          @click="submit('fromData')"
          >保存</el-button
        >
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="openbk"
      width="32%"
      :before-close="handlesClose">
      <div class="dialogbox">
        <el-form :model="queryxq" ref="queryxq" label-width="150px">
          <el-form-item label="学年" prop="semester_year">
            <el-select v-model="queryxq.semester_year" placeholder="请选择">
              <el-option
                v-for="item in yearlist"
                :key="item.yearid"
                :label="item.yearid"
                :value="item.yearid">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学期" prop="semester_season">
            <el-select v-model="queryxq.semester_season" placeholder="请选择">
              <el-option
                v-for="item in semesterlist"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="formiten"
            label="本学期开始时间"
            prop="semester_start_time">
            <el-date-picker
              v-model="queryxq.semester_start_time"
              type="date"
              placeholder="选择本学期开始时间"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item
            class="formiten"
            label="本学期结束时间"
            prop="semester_end_time">
            <el-date-picker
              v-model="queryxq.semester_end_time"
              type="date"
              placeholder="选择本学期结束时间"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="本学学期周数" prop="semester_week_num">
            <div class="formiten">
              <el-input
                v-model="queryxq.semester_week_num"
                type="number"
                placeholder="请输入内容"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="是否为当前学期" prop="is_enable">
            <el-radio v-model="queryxq.is_enable" :label="1">是</el-radio>
            <el-radio v-model="queryxq.is_enable" :label="0">否</el-radio>
          </el-form-item>
        </el-form>
        <div class="anniu">
          <el-button
            class="resBtn"
            size="mini"
            type="primary"
            @click="submitSemester('queryxq')"
            >保存</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addsave,
  semesters,
  updateSemester,
  deleteSemesters,
  getkcdata,
  addSemester,
  getidxq,
} from "@/api/classroon/zxdata";
export default {
  created() {
    var nowDate = new Date();
    var date = {
      year: nowDate.getFullYear(),
      month: nowDate.getMonth() + 1,
      day: nowDate.getDate(),
    };

    const xiajiDate = date.year + "-" + "05" + "-" + "01";
    const dongjiDate = date.year + "-" + "10" + "-" + "01";
    var date = new Date();
    this.$set(this.fromData, "summer_time", xiajiDate.toString());
    this.$set(this.fromData, "winter_time", dongjiDate.toString());
    this.$set(this.yearlist[0], "yearid", date.getFullYear() - 1);
    this.$set(this.yearlist[1], "yearid", date.getFullYear());
    this.$set(this.yearlist[2], "yearid", date.getFullYear() + 1);
    this.getsemester();
    this.getcoursesdata();
  },
  components: {},
  data() {
    return {
      openbk: false,
      act: 0,
      dijijieke: 0,
      semesterlist: [
        {
          id: 1,
          name: "第一学期",
        },
        {
          id: 2,
          name: "第二学期",
        },
      ],
      yearlist: [{}, {}, {}],
      postLists: [],
      defaultDate: new Date(),
      queryxq: {
        semester_week_num: 22,
        is_enable: 1,
        semester_start_time: "",
        semester_end_time: "",
      },
      fromData: {
        schedule: {
          summer: [
            {
              class_index: 1,
              start_time: "",
              end_time: "",
              course_type: 1,
              time_table_type: 1,
              class_num: 1,
            },
          ],
          winter: [
            {
              class_index: 1,
              start_time: "",
              end_time: "",
              course_type: 1,
              time_table_type: 2,
              class_num: 1,
            },
          ],
        },
      },
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      items: [
        { name: "作息时间" },
        // , { name: "冬季" }
      ],
      startdata: [],
      rules: {
        semester_start_time: [
          { required: true, message: "输入资源名称", trigger: "change" },
        ],
        summer_time: [
          { required: true, message: "输入资源名称", trigger: "change" },
        ],
        winter_time: [
          { required: true, message: "输入资源名称", trigger: "change" },
        ],
        schedule: [
          { required: true, message: "输入资源名称", trigger: "change" },
        ],
      },
      title: "新增学期",
    };
  },
  methods: {
    //删除
    deleteById(row) {
      console.log("row", row);

      this.$confirm("确认删除吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          deleteSemesters({
            row: {
              id: row,
            },
          }).then(() => {
            this.getsemester();
            this.$message.success("操作成功。");
          });
        })
        .catch(() => {
          this.$message.info("取消删除。");
        });
    },
    // 编辑夏季大课间
    handleChangesummer(val) {
      console.log(val);
      let num = 0;
      let classIndex = 0;
      this.fromData.schedule.summer.forEach((item, index) => {
        console.log(item, index);

        // if (item.course_type == 2) {
        //   item.class_index = null;

        // } else {
        classIndex++;
        item.class_index = classIndex;

        // }

        if (item.course_type == 2) {
          item.class_num = null;
        } else {
          num++;
          item.class_num = num;
        }
      });
    },

    // 编辑冬季大课间
    handleChangewinter(val) {
      console.log(val);
      let num = 0;
      let classIndex = 0;
      this.fromData.schedule.winter.forEach((item, index) => {
        console.log(item, index);
        // if (item.course_type == 2) {
        //   item.class_index = null;
        // } else {
        // num++;
        // item.class_index = num;
        // }

        console.log(item, index);

        // if (item.course_type == 2) {
        //   item.class_index = null;

        // } else {
        classIndex++;
        item.class_index = classIndex;

        // }

        if (item.course_type == 2) {
          item.class_num = null;
        } else {
          num++;
          item.class_num = num;
        }
      });
      console.log(
        this.fromData.schedule.winter,
        " this.fromData.schedule.winter"
      );
    },
    //编辑
    bianji(row) {
      this.title = "编辑学期";
      this.openbk = true;
      this.queryxq.id = row.id;
      this.xiangqing = row.id;
      this.getIdshuju();
    },
    getIdshuju() {
      getidxq({
        id: this.xiangqing,
      }).then((res) => {
        this.queryxq = res.data;
        console.log(res, "resshuju");
      });
    },
    //获取列表
    getsemester() {
      semesters().then((res) => {
        this.postLists = res.data;
        console.log(this.postLists);
      });
    },
    //关闭弹窗
    handlesClose() {
      this.openbk = false;
      this.clears1();
    },
    //打开新增弹框
    addSemester() {
      this.title = "新增学期";
      this.openbk = true;
      this.queryxq = {
        semester_week_num: 22,
        is_enable: 1,
        semester_start_time: "",
        semester_end_time: "",
      };
    },
    //新增/编辑学期数据
    submitSemester() {
      console.log("this.queryxq", this.queryxq);
      if (this.queryxq.id == null) {
        addSemester({
          row: this.queryxq,
        }).then((res) => {
          if (res.msg == "保存成功") {
            this.$message.success(res.msg);
            this.clears1();
            this.getsemester();
          }
        });
      } else {
        updateSemester({
          row: this.queryxq,
        }).then((res) => {
          if (res.msg == "更新成功") {
            this.$message.success(res.msg);
            this.clears1();
            this.getsemester();
          }
        });
      }

      this.$emit("ok");
    },
    //保存课程时间
    submit(v) {
      console.log("fromData", this.fromData);
      let data = {
        schedule: {
          summer: this.fromData.schedule.summer,
          winter: this.fromData.schedule.winter,
        },
        summer_time: this.fromData.summer_time,
        winter_time: this.fromData.winter_time,
      };
      console.log(data, "=data");
      // data.schedule.summer.forEach((item, index) => {
      //   delete item.class_num;
      // });
      // data.schedule.winter.forEach((item, index) => {
      //   delete item.class_num;
      // });
      this.$refs[v].validate((valid) => {
        if (valid) {
          addsave({
            row: data,
          }).then((res) => {
            if (res.msg == "保存成功") {
              this.$message.success(res.msg);
              this.fromData = res.data;
              this.getcoursesdata();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取课程时间数据
    getcoursesdata() {
      getkcdata().then((res) => {
        console.log(res.data.schedule.summer, "课程时间数据接口返回");
        if (res.data.schedule.summer.length > 0) {
          this.fromData.schedule = res.data.schedule;
          let summernum = 0;
          this.fromData.schedule.summer.forEach((item, index) => {
            if (item.course_type == 2) {
              item.class_num = null;
            } else if (item.course_type == 1) {
              summernum++;
              item.class_num = summernum;
            }
          });

          let winternum = 0;
          this.fromData.schedule.winter.forEach((item, index) => {
            if (item.course_type == 2) {
              item.class_num = null;
            } else if (item.course_type == 1) {
              winternum++;
              item.class_num = winternum;
            }
          });
          console.log(this.fromData.schedule, "课程时间数据1111");
        }
      });
    },
    clears1() {
      this.queryxq.is_enable = 1;
      this.queryxq.semester_end_time = "";
      this.queryxq.semester_season = "";
      this.queryxq.semester_start_time = "";
      this.queryxq.semester_year = "";
      this.openbk = false;
    },
    clears() {
      // this.fromData.schedule.summer = [{
      //   class_index: 1,
      //   start_time: '',
      //   end_time: ''
      // }]
      // this.fromData.schedule.winter = [{
      //   class_index: 1,
      //   start_time: '',
      //   end_time: ''
      // }]
    },
    addkeshi(index) {
      let summerName = "";
      let summerNum = "";
      let summer = this.fromData.schedule.summer;
      let winter = this.fromData.schedule.winter;

      summer.forEach((item, index) => {
        if (item.course_type == 1) {
          summerName = item.class_num + 1;
        }
        if (index == 0 && item.course_type != 1) {
          summerName = 1;
        }
        summerNum = item.class_index + 1;
      });
      var winterName = "";
      let winterNum = "";
      winter.forEach((item, index) => {
        console.log(item);
        if (index == 0 && item.course_type != 1) {
          winterName = 1;
        }
        if (item.course_type == 1) {
          winterName = item.class_num + 1;
        }
        winterNum = item.class_index + 1;
      });

      if (this.act == 0) {
        console.log(summerName, "===summerName");
        this.fromData.schedule.summer.push({
          // this.fromData.schedule.summer.length + 1,
          class_index: summerNum,
          start_time: "",
          end_time: "",
          course_type: 1,
          time_table_type: 1,
          class_num: summerName,
        });
      } else {
        console.log(winterName, "=winterName");
        this.fromData.schedule.winter.push({
          class_index: winterNum,
          // class_index: this.fromData.schedule.winter.length + 1,
          start_time: "",
          end_time: "",
          course_type: 1,
          time_table_type: 2,
          class_num: winterName,
        });
      }
    },
    deleyes(i) {
      if (this.act == 0) {
        let val = null;
        let val2 = 0;

        this.fromData.schedule.summer.splice(i, 1);
        for (
          let index = 0;
          index < this.fromData.schedule.summer.length;
          index++
        ) {
          val = this.fromData.schedule.summer;
          val[index].class_index = index + 1;
          if (val[index].course_type == 1) {
            val2++;
            val[index].class_num = val2;
          }
        }
      } else {
        let val = null;
        let val2 = 0;
        this.fromData.schedule.winter.splice(i, 1);
        for (
          let index = 0;
          index < this.fromData.schedule.winter.length;
          index++
        ) {
          val = this.fromData.schedule.summer;
          val[index].class_index = index + 1;
          if (val[index].course_type == 1) {
            val2++;
            val[index].class_num = val2;
          }
        }
      }
    },
    timevaluechange(val) {
      console.log("时间数据", val);
      if (val) {
        this.fromData.semester_start_time = val[0];
        this.fromData.semester_end_time = val[1];
      } else {
        this.fromData.semester_start_time = "";
        this.fromData.semester_end_time = "";
      }
    },
    timevaluechange1(v) {
      console.log(this.fromData);
      console.log('"时间数据"', v);
    },
    changeact(i) {
      this.act = i;
      console.log(this.act);
    },
  },
};
</script>

<style lang="less" scoped>
// /deep/ .formiten {
//   /deep/.el-input,
//   /deep/ .el-date-editor {
//     width: 100% !important;
//   }
// }

.dialogbox {
  /deep/.el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
.dialogbox {
  border: 1px solid #b4b4b4;
  padding: 20px;
  box-sizing: border-box;
  .formiten {
    // width: 220px;
  }
}
.frombox {
  margin-top: 40px;
  background: #fff;
  margin: 20px 20px 0px 20px;
  padding: 20px 0px;

  .titlename {
    margin-left: 60px;
    font-size: 20px;

    .tabs {
      padding: 20px 60px 0px 0px;
      ::-webkit-scrollbar {
        width: 10px !important;
        height: 10px !important;
        // background-color: #ccc !important;
      }
      ::-webkit-scrollbar-track {
        // background-color: #bbbbbb;
      }
      /* 滚动条上的滚动滑块 */
      ::-webkit-scrollbar-thumb {
        background-color: #ccc;
      }

      .tuimg {
        width: 140px;
        height: 50px;
      }
    }
  }

  .shurubox {
    width: 400px;
  }

  .shurubox1 {
    width: 500px;
    width: 80%;
  }

  .shurubox2 {
    width: 220px;
  }

  .anniu {
    margin: 20px 0px 0px 180px;
  }

  .jijiebox {
    width: 60px;
    text-align: center;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
  }

  .zuoxibox {
    margin: 20px 0px 0px 180px;
    padding: 20px;
    background: #fff;
    border: 1px solid #fff;
    // width: 640px;
    min-height: 20px;

    .kejieshu {
      // width: 640px;
      margin-bottom: 10px;
    }
  }

  .active {
    background: #a7a3a3;
  }
  .switch_title {
    margin-left: 12px;
  }
  .switch {
    margin: 0 12px;
  }
}
</style>
