<template>
	<div class="contents-search">
    <el-input
    placeholder="请输入内容"
    @input="aa(value)"
    v-model="value"
		clearable
		>
  </el-input>
	<div class="imgbox" @click="search">
		<img src="../assets//classroon/outline.png" alt="">
	</div>
		<!-- <button @click="search" :style="`background-color: ${bgcolor};`">
			搜索
		</button> -->
	</div>
</template>
<script>
export default {
	name: 'search',
	components: {},
	props: {
		bgcolor: {
			type: String,
			default: '#05b4b1',
		},
	},
	data() {
		return {
			value: null,
		}
	},
	methods: {
		aa(e) {
			this.$emit('input', e)
      this.value=e
		},
		search() {
			this.$emit('search', this.value)
		},
		//清空输入框
		clearvalue(){
			this.value = ''
		}
	},
	watch: {},
	computed: {},
	created() {},
	mounted() {},
}
</script>
<style lang="less" scoped>
.contents-search {
	display: flex;
	justify-content: flex-end;
	height: 40px;
	width: 272px;
	.imgbox{
		width: 40px;
		height: 40px;
		text-align: center;
		border-radius: 10px 10px 10px 10px;
		background: #516CF7;
		img{
			margin-top: 5px;
		}
	}
	input {
		padding-left: 52px;
		width: 272px;
		height: 40px;
		border: solid 1px #dcdcdc;
		border-right: none;
		border-radius: 10px 10px 10px 10px;
	}
 
	button {
		//padding-left: 20px;
		width: 100px;
		height: 40px;
		border: solid 1px #dcdcdc;
		border-left: none;
		border-radius: 0 4px 4px 0;
		color: #fff;
		font-size: 16px;
	}
}
</style>
