<template>
  <div class="resourcebox">
    <div class="resource" v-for="(item, index) in resource" :key="index">
      <div class="wcbox">
        <div class="leftbox">
          <div class="img" v-if="item.cover != null">
            <img style="width: 100%;" :src="item.cover" alt="">
          </div>
          <div v-else>
            <img class="img" v-if="active<2"  src="../../../assets/zanweitu.png" alt="">
            <img class="img" v-if="active==2"  src="../../../assets//classroon/ziyuanbao.png" alt="">
          </div>
          <div class="neirong" >
            <div class="titlename">{{item.name|| item.prepare_name || '暂无名称'}}</div>
            <!-- <div class="zixinxi">2023-4-1 6:08:33 | 长沙市岳麓区望月湖第一小学 | 张老师</div>
            <div class="guide">
              <div>上传至：公共资源</div>
              <div class="nianji">适用年级：一年级</div>
              <div class="tupian">
                <img src="../../../assets//classroon/xinxi.png" alt="">
                <img src="../../../assets//classroon/xiazai.png" alt="">
              </div>
            </div> -->
          </div>
        </div>
        <div class="rightbox">
          <el-button v-if="item.state" type="primary" round @click="quote(item,index)">取消引用</el-button>
          <el-button v-else type="primary" round @click="quote(item,index)">引用</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import zhjyPagination from '@/components/zhjyPagination'
export default {
  components:{
    zhjyPagination
  },
  props: {
    resource: Array,
    active:Number
  },
  created() {
  },
  data() {
    return {
      query:{},
      quoteclass:[],  //存储资源数据
      act:1,
      quoteid:[],  // 存取资源id
     
    }
  },
  methods:{
    quote(i,j){
      i.state = !i.state
      if(i.state){
        //存储数组添加数据
        for(let z of i.items){
          console.log(z);
          this.quoteclass.push(z)
          this.quoteid.push(z.id)
        }
        
      }else{
        for(let z of i.items){
          //取消引用
          if(this.quoteclass.indexOf(z) != -1){
            this.quoteclass.splice(this.quoteclass.indexOf(i),1)
          }
          //删除选中id
          if(this.quoteid.indexOf(z.id) != -1){
            this.quoteid.splice(this.quoteid.indexOf(z.id),1)
          }
        }
        
      }
      //保存到stote
      this.$store.commit('quoteclass', this.quoteclass)
      this.$store.commit('quoteid', this.quoteid)
    },
    clearquote(){
      this.quoteclass = []
      this.quoteid = []
      console.log('调用了');
    }
  }
}
</script>

<style lang="less" scoped>
.resourcebox {
  margin-top: 30px;
  width: 100%;
  height: 392px;
  overflow-y: scroll;
  font-size: 16px;
  .resource {
    height: 135px;
    background: #FFFFFF;
    margin-bottom: 20px;
    border-radius: 20px 70px 20px 20px;
    border: 1px solid #4A80F7;

    .wcbox {
      display: flex;

      .leftbox {
        width: 546px;
        height: 100px;
        margin: 17px 0px 0px 17px;
        display: flex;

        .img {
          width: 100px;
          height: 100px;
        }

        .neirong {
          margin: 2px 0px 0px 23px;
          .titlename{
            width: 367px;
            overflow:hidden;
            white-space:nowrap;
            text-overflow:ellipsis;
          }
          .zixinxi {
            margin-top: 16px;
            color: #8392A9;
          }

          .guide {
            display: flex;
            margin-top: 12px;
            color: #8392A9;
            .nianji{
              margin-left: 25px;
            }
            .tupian{
              margin-left: 30px;
              img{
                margin-right: 20px;
              }
            }
          }
        }
      }
      .rightbox{
        margin: 50px 0px 0px 45px;
      }
    }
  }
}</style>