<template>
  <div class="resourcebox">
    <div class="resource" v-for="(item, index) in resource" :key="index">
      <div class="wcbox">
        <div class="leftbox">
          <div class="img" v-if="item.cover != null">
            <img style="width: 100%" :src="item.cover" alt="" />
          </div>
          <div v-else>
            <img
              class="img"
              v-if="active < 2"
              src="../../../assets/zanweitu.png"
              alt=""
            />
            <img
              class="img"
              v-if="active == 2"
              src="../../../assets//classroon/ziyuanbao.png"
              alt=""
            />
          </div>
          <div class="neirong">
            <div class="titlename">
              {{ item.name || item.prepare_name || "暂无名称" }}
            </div>
            <el-button
              style="margin-top: 10px"
              @click="handlePrew(item)"
              type="primary"
              round
              >查看</el-button
            >

            <!-- <div class="zixinxi">2023-4-1 6:08:33 | 长沙市岳麓区望月湖第一小学 | 张老师</div>
            <div class="guide">
              <div>上传至：公共资源</div>
              <div class="nianji">适用年级：一年级</div>
              <div class="tupian">
                <img src="../../../assets//classroon/xinxi.png" alt="">
                <img src="../../../assets//classroon/xiazai.png" alt="">
              </div>
            </div> -->
          </div>
        </div>
        <div class="rightbox">
          <el-button
            v-if="item.state"
            type="primary"
            round
            @click="quote(item, index)"
            >取消引用</el-button
          >
          <el-button v-else type="primary" round @click="quote(item, index)"
            >引用</el-button
          >
          <!-- <el-button v-if="item.state" type="primary" round @click="quote(item, index)">取消跟我练引用</el-button>
          <el-button v-else type="primary" round @click="quote(item, index)">跟我练引用</el-button> -->
        </div>
        <div class="rightbox1" v-if="active == 0 && item.houzhui == 'mp4'">
          <el-button
            v-if="item.genwolian"
            type="primary"
            round
            @click="genwoxx(item, index)"
            >取消跟我练</el-button
          >
          <el-button v-else type="primary" round @click="genwoxx(item, index)"
            >引用跟我练</el-button
          >
          <!-- <el-button v-if="item.state" type="primary" round @click="quote(item, index)">取消跟我练引用</el-button>
          <el-button v-else type="primary" round @click="quote(item, index)">跟我练引用</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import zhjyPagination from "@/components/zhjyPagination";
export default {
  components: {
    zhjyPagination,
  },
  props: {
    resource: Array,
    active: Number,
  },
  created() {
    console.log("quoteclass", this.quoteclass);
    console.log("quoteclass", this.quoteid);
  },
  data() {
    return {
      query: {},
      quoteclass: [], //存储资源数据
      act: 1,
      quoteid: [], // 存取资源id
      ziyuanbaoid: [], // 存取资源包id
      genwolian: [], //存储跟我练数据
      cunchuziy: [],
      ccgenwolian: [], //存储提交跟我练数据
      duibiId1: [],
      duibiId2: [],
    };
  },
  methods: {
    handlePrew(val) {
      console.log(val);
      window.open(val.file_url);
    },
    quote(i, j) {
      i.state = !i.state;
      if (i.state) {
        //存储数组添加数据
        if (i.items != undefined || i.item != null) {
          this.ziyuanbaoid.push(i.id);
          console.log("push了", this.ziyuanbaoid);
          for (let z of i.items) {
            for (let k of i.resource) {
              if (k.type == 1) {
                if (k.resource_id == z.id) {
                  this.quoteclass.push(z);
                  this.quoteid.push(z.id);
                  this.duibiId1.push(z.id);
                  this.cunchuziy.push({ resource_id: z.id, type: 1 });
                  console.log("xxx");
                }
              }
              if (k.type == 2) {
                if (k.resource_id == z.id) {
                  this.genwolian.push(z);
                  this.quoteid.push(z.id);
                  this.duibiId2.push(z.id);
                  this.ccgenwolian.push({ resource_id: z.id, type: 2 });
                }
              }
            }
            console.log(z, "zzzzzzzzzzzz");
          }
        } else {
          // this.genwolian.push(i)
          this.quoteclass.push(i);
          this.cunchuziy.push({ resource_id: i.id, type: 1 });
          this.quoteid.push(i.id);
          this.duibiId1.push(i.id);
          console.log("shanchu2", this.quoteclass);
          console.log("cunchuziy", this.cunchuziy);
          console.log("duibiId1", this.duibiId1);
        }
      } else {
        if (i.items != undefined || i.item != null) {
          //取消资源包

          if (this.ziyuanbaoid.indexOf(i.id) != -1) {
            this.ziyuanbaoid.splice(this.ziyuanbaoid.indexOf(i.id), 1);
            console.log("shanchu1");
          }
          for (let z of i.items) {
            //取消引用
            // if (this.quoteclass.indexOf(z) != -1) {
            //   this.quoteclass.splice(this.quoteclass.indexOf(z), 1)
            // }
            //删除选中id
            let zybitemsid = this.quoteid.indexOf(z.id);
            if (zybitemsid != -1) {
              this.quoteid.splice(zybitemsid, 1);
              this.quoteclass.splice(zybitemsid, 1);
              this.duibiId1.splice(zybitemsid, 1);
              this.cunchuziy.splice(zybitemsid, 1);
              this.duibiId2.splice(zybitemsid, 1);
              this.genwolian.splice(zybitemsid, 1);
              this.ccgenwolian.splice(zybitemsid, 1);
            }
          }
        } else {
          //取消资源、教案
          // console.log(this.quoteclass.indexOf(i),this.quoteid.indexOf(i.id));
          //取消引用
          // if (this.quoteclass.indexOf(i) != -1) {
          //   this.quoteclass.splice(this.quoteclass.indexOf(i), 1)
          // }
          //删除选中id
          let iitemsid = this.quoteid.indexOf(i.id);
          console.log(iitemsid, "iitemsid");
          if (iitemsid != -1) {
            this.quoteid.splice(iitemsid, 1);
            this.quoteclass.splice(iitemsid, 1);
            this.cunchuziy.splice(iitemsid, 1);
            this.duibiId1.splice(iitemsid, 1);
          }
        }
      }
      //保存到stote   cunchuziy
      this.$store.commit("duibiId1", this.duibiId1);
      this.$store.commit("duibiId2", this.duibiId2);
      this.$store.commit("ccgenwolian", this.ccgenwolian);
      this.$store.commit("quoteclass", this.quoteclass);
      this.$store.commit("quoteid", this.quoteid);
      this.$store.commit("ziyuanbaoid", this.ziyuanbaoid);
      this.$store.commit("cunchuziy", this.cunchuziy);
      this.$store.commit("genwolian", this.genwolian);
    },
    genwoxx(i, j) {
      i.genwolian = !i.genwolian;
      if (i.genwolian) {
        //存储数组添加数据
        if (i.items != undefined || i.item != null) {
          this.ziyuanbaoid.push(i.id);
          console.log("push了", this.ziyuanbaoid);
          for (let z of i.items) {
            console.log(z, "zzzzzzzzzzzz");
            this.quoteclass.push(z);
            this.quoteid.push(z.id);
            this.duibiId2.push(i.id);
          }
        } else {
          this.genwolian.push(i);
          this.ccgenwolian.push({ resource_id: i.id, type: 2 });
          this.quoteid.push(i.id);
          this.duibiId2.push(i.id);
          console.log("genwolian", this.genwolian);
        }
      } else {
        if (i.items != undefined || i.item != null) {
          //取消资源包
          console.log("shanchu1");
          if (this.ziyuanbaoid.indexOf(i.id) != -1) {
            this.ziyuanbaoid.splice(this.ziyuanbaoid.indexOf(i.id), 1);
          }
          for (let z of i.items) {
            //取消引用
            // if (this.quoteclass.indexOf(z) != -1) {
            //   this.quoteclass.splice(this.quoteclass.indexOf(z), 1)
            // }
            //删除选中id
            let zybitemsid = this.quoteid.indexOf(z.id);
            if (zybitemsid != -1) {
              this.quoteid.splice(zybitemsid, 1);
              this.quoteclass.splice(zybitemsid, 1);
            }
          }
        } else {
          //取消资源、教案
          // console.log(this.quoteclass.indexOf(i),this.quoteid.indexOf(i.id));
          //取消引用
          // if (this.quoteclass.indexOf(i) != -1) {
          //   this.quoteclass.splice(this.quoteclass.indexOf(i), 1)
          // }
          //删除选中id
          let iitemsid = this.quoteid.indexOf(i.id);
          console.log(iitemsid, "iitemsid");
          if (iitemsid != -1) {
            this.quoteid.splice(iitemsid, 1);
            this.ccgenwolian.splice(iitemsid, 1);
            this.genwolian.splice(iitemsid, 1);
            this.duibiId2.splice(iitemsid, 1);
          }
        }
      }
      //保存到stote
      this.$store.commit("duibiId2", this.duibiId2);
      this.$store.commit("ccgenwolian", this.ccgenwolian);
      this.$store.commit("quoteid", this.quoteid);
      this.$store.commit("ziyuanbaoid", this.ziyuanbaoid);
      this.$store.commit("genwolian", this.genwolian);
    },
    //自动添加数据a
    addxqshuju(a, b) {
      if (b == 1) {
        this.quoteclass.push(a);
        this.duibiId1.push(a.id);
        this.quoteid.push(a.id);
        this.cunchuziy.push({ resource_id: a.id, type: b });
        this.$store.commit("duibiId1", this.duibiId1);
        this.$store.commit("cunchuziy", this.cunchuziy);
        this.$store.commit("quoteclass", this.quoteclass);
        this.$store.commit("quoteid", this.quoteid);
      }
      if (b == 2) {
        this.genwolian.push(a);
        this.duibiId2.push(a.id);
        this.quoteid.push(a.id);
        this.ccgenwolian.push({ resource_id: a.id, type: b });
        this.$store.commit("duibiId2", this.duibiId2);
        this.$store.commit("genwolian", this.genwolian);
        this.$store.commit("ccgenwolian", this.ccgenwolian);
        this.$store.commit("quoteid", this.quoteid);
      }
      // this.quoteid.push(a.id)
      // this.genwolian.push(a)
      // this.$store.commit('quoteclass', this.quoteclass)
      // this.$store.commit('quoteid', this.quoteid)
    },
    clearquote() {
      this.quoteclass = [];
      this.quoteid = [];
      this.ziyuanbaoid = [];
      this.genwolian = [];
      this.cunchuziy = [];
      this.ccgenwolian = [];
      this.duibiId1 = [];
      this.duibiId2 = [];
      console.log("sfsad", this.resource);
      console.log("调用了");
    },
  },
};
</script>

<style lang="less" scoped>
.resourcebox {
  margin-top: 30px;
  width: 100%;
  height: 392px;
  overflow-y: scroll;
  font-size: 16px;

  .resource {
    height: 135px;
    background: #ffffff;
    margin-bottom: 20px;
    border-radius: 20px 70px 20px 20px;
    border: 1px solid #4a80f7;

    .wcbox {
      display: flex;

      .leftbox {
        width: 546px;
        height: 100px;
        margin: 17px 0px 0px 17px;
        display: flex;

        .img {
          width: 100px;
          height: 100px;
        }

        .neirong {
          margin: 2px 0px 0px 23px;

          .titlename {
            width: 367px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .zixinxi {
            margin-top: 16px;
            color: #8392a9;
          }

          .guide {
            display: flex;
            margin-top: 12px;
            color: #8392a9;

            .nianji {
              margin-left: 25px;
            }

            .tupian {
              margin-left: 30px;

              img {
                margin-right: 20px;
              }
            }
          }
        }
      }

      .rightbox {
        margin: 50px 0px 0px 0px;
      }
      .rightbox1 {
        margin: 50px 0px 0px 5px;
      }
    }
  }
}
</style>
