let weekCourse = [
  {
    week: 0,
    courses: [
      {
        index: 1,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "高等数学", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 3,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "语文", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
    ],
  },
  {
    week: 1,
    courses: [
      {
        index: 1,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "物理", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 2,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "高等数学", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 3,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "语文", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 4,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "大学英语", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 5,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "Vue", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 6,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "Vue", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 7,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "Java", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
     
    ],
  },
  {
    week: 2,
    courses: [
      {
        index: 5,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "物理", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 2,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "语文", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
    ]
  },
  {
    week: 3,
    courses: [
      {
        index: 1,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "物理", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 2,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "物理", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 3,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "语文", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
    ],
  },
  {
    week: 4,
    courses: [
      {
        index: 1,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "物理", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 3,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "语文", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
    ],
  },
  {
    week: 5,
    courses: [
      {
        index: 4,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "物理", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 3,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "语文", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
    ],
  },
  {
    week: 6,
    courses: [
      {
        index: 2,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "物理", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
      {
        index: 3,
        startTime: "08:00", //开始时间
        endTime: "09:30", //结束时间
        subject: "语文", //学科
        major: "计算机科学与技术", //专业
        class: "1702班", //班级
      },
    ],
  },
 
]//课程详细信息
let colorList = [
  "#4BBDEE",
  "#E86EEB",
  "#4BBDEE",
  "#13DAC2",
  "#7464FB",
  "#F18141",
  "#57C946",
  "#E8BE2A",
  "#508AFA",
]
export {
  weekCourse, colorList
}