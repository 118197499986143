<template>
  <div class="pagination">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[10]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    pageNum: {
      default: function () {
        return 1;
      },
    },
    pageSize: {
      default: function () {
        return 10;
      },
    },
    total: {
      default: function () {
        return null;
      },
    },
  },

  methods: {
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
    changePageNum() {
      this.$emit("update:pageNum", 1);
    },
  },
};
</script>
<style lang="less">
.pagination {
  display: flex;
  justify-content: center;
  padding: 0.2rem 0;
}
</style>
