<template>
  <el-upload
    class="upload-demo"
    multiple
    :format="formatArray"
    :action="file_system_url"
    :show-file-list="false"
    :on-success="uploadSuccess"
    :on-error="uploadError"
    :before-upload="beforeUpload"
    :accept="accept"
    :on-format-error="handleFormatError"
    :data="paramsData">
    <el-button type="danger">
      <i class="iconfont eicon-szkc-shangchuan"></i>
      {{ title }}
    </el-button>
  </el-upload>
</template>
<script>
import { getOss } from "@/utils/auth";
export default {
  props: {
    title: {
      type: String,
      default: "上传",
    },
    accept: {
      type: String,
      default:
        ".docx,.doc,.xlsx,.pptx,.ppt,.pdf,.png,.jpeg,.jpg,.gif,.zip,.rar,.txt,.mp4,.flv,.3gp,.avi",
    },
  },
  data: () => ({
    file_system_url:
      "https://sisheng-head.oss-cn-shenzhen.aliyuncs.com/resource",
    formatArray: [],
    signature: {},
    paramsData: {
      accessKeyId: getOss().accessKeyId, // 你创建的Bucket时获取的
      accessKeySecret: getOss().accessKeySecret,
      bucket: "sisheng-resource",
      region: "oss-cn-shenzhen",
    },
  }),
  // created() {
  //   ossUrl().then((res) => {
  //     if (res.code == 1) {
  //       this.file_system_url = res.file_system_url;
  //       this.signature = res.signature;
  //       this.paramsData = {
  //         key: this.signature.dir + "/" + this.signature.random + "/", //这里最好在后台处理好
  //         policy: this.signature.policy,
  //         OSSAccessKeyId: this.signature.accessid,
  //         success_action_status: "200", //让服务端返回200,不然，默认会返回204
  //         callback: this.signature.callback,
  //         signature: this.signature.signature,
  //         "x:space": this.signature.spaceName,
  //         "x:identity": this.signature.identity,
  //       };
  //     }
  //   });
  // },
  methods: {
    handMenu(path) {
      this.$router.push(path);
    },
    uploadSuccess(res, file) {
      if (res.code == 1) {
        res.fileName = file.name;
        this.$emit("successData", res);
      }
    },
    beforeUpload(file) {
      this.paramsData.key = this.paramsData.key + file.name;
      if (this.accept) {
        const str = "/.(" + this.accept.replace(/,/g, "?|") + "?" + ")$/i";
        const fileName = file.name.toLowerCase();
        const name = eval(str).test(fileName);
        if (!name) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: "支持文件格式：" + "\n" + this.accept,
            type: "warning",
          });
          return false;
        }
      }
    },
    handleFormatError: function () {
      // 文件格式上传不正确
      this.$Notice.warning({
        title: "上传文件格式错误",
        desc: "文件格式不正确, 请选择" + this.formatArray + "格式文件上传。",
      });
    },
    uploadError: function () {
      this.$Message.error("上传失败！");
    },
  },
};
</script>

<style lang="less"></style>
