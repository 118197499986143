<template>
  <div>
    <div class="projectbox">
      <div class="project" :class="{act:act==index}" @click="change(index)" v-for="(item, index) in typrojectlist" :key="index">{{ item }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    typrojectlist:Array
  },
  data() {
    return {
      act:0
    }
  },
  methods: {
    change(i){
      this.act = i
    }
  },
}
</script>

<style lang="less" scoped>
.el-button--primary{
    background-color: #516CF7 !important;
    border-color: #516CF7 !important;
    width: 111px;
}
.projectbox {
      display: flex;
      height: 50px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      background: #F2F7FF;
      margin-top: 22px;
      padding-left: 18px;

      .project {
        margin-right: 50px;
        margin-top: 10px;
        padding: 0px 5px 0px 5px;
        height: 31px;
        line-height: 31px;
        color: #000000;
        border-radius: 6px 6px 6px 6px;
        cursor: pointer;
      }
      .act{
        background: #516CF7;
        color: #F2F7FF;
      }
    }
</style>

<style >
.el-button--primary{
    background-color: #516CF7 !important;
    border-color: #516CF7 !important;
    width: 111px;
}
</style>