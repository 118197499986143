import request from "@/utils/request";
import { baseUrl } from "@/utils/config";

//添加作息时间
export function addsave(data) {
  return request({
    url: baseUrl + "/xx/saveTimeTable",
    method: "post",
    data,
  });
}
//获取课程时间数据
export function getkcdata(data) {
  return request({
    url: baseUrl + "/xx/saveTimeTable",
    method: "get",
    params: data,
  });
}
//教育局端新增学期
export function addSemester(data) {
  return request({
    url: baseUrl + "/xx/semesters/saveSemester",
    method: "post",
    data,
  });
}

//获取学期列表接口
export function semesters(data) {
  return request({
    url: baseUrl + "/xx/semesters",
    method: "get",
    data,
  });
}
//获取修改学期数据
export function updateSemester(data) {
  return request({
    url: baseUrl + "/xx/semesters/updateSemester",
    method: "post",
    data,
  });
}
//根据id获取数据
export function getidxq(data) {
  return request({
    url: baseUrl + "/xx/semesters/updateSemester",
    method: "get",
    params: data,
  });
}
//删除学期
export function deleteSemesters(data) {
  return request({
    url: baseUrl + "/xx/semesters/deleteSemester",
    method: "post",
    data,
  });
}
