import request from "@/utils/request";
import { baseUrl } from "@/utils/config";

export function semester(data) {
  return request({
    url: baseUrl + "/xx/semester",
    method: "get",
    params: data,
  });
}

export function ceshi(data) {
  return request({
    url: "http://192.168.0.118:8000/api/test11",
    method: "post",
    data,
  });
}
